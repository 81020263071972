@import '../../styles/common/common.scss';

$border-color: rgba(183, 183, 183, 0.2);

.partners_grid_wrapper {
    position: relative;
    padding-top: var(--component-unhook-height);
    padding-bottom: var(--component-unhook-height);
    margin-top: var(--component-unhook-height);
}

.partners_grid {
    //background-color: var(--utmb-color-white);
    height: auto;
    max-width: 100vw;
    overflow-x: hidden;

    .partners_grid_container {
        width: 100%;
        justify-content: space-around;
        row-gap: rem(30px);

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: rem(110px);

            &.one_element {
                max-width: rem(280px);

                &.larger {
                    max-width: rem(320px);
                }

                span {
                    max-height: rem(250px);
                }
            }

            &.two_elements {
                max-width: rem(250px);

                &.larger {
                    max-width: rem(290px);
                }

                span {
                    max-height: rem(230px);
                }
            }

            &.three_elements {
                max-width: rem(200px);

                &.larger {
                    max-width: rem(240px);
                }

                span {
                    max-height: rem(190px);
                }
            }

            &.four_elements {
                max-width: rem(160px);

                &.larger {
                    max-width: rem(200px);
                }

                span {
                    max-height: rem(140px);
                }
            }

            &.more_elements {
                max-width: rem(120px);

                &.larger {
                    max-width: rem(160px);
                }

                span {
                    max-height: rem(120px);
                }
            }

            @media (orientation: portrait) {
                flex: 0 0 50%;
                text-align: center;
                padding: 0 rem(10px);
            }
        }
    }
}

.partners_before {
    width: 55vw;
    height: var(--component-unhook-height);
    background: var(--utmb-color-white);
    position: absolute;
    top: -5rem;
    z-index: 10;

    .partners_before_c {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--utmb-color-white);

            transform-origin: bottom left;
            -ms-transform: skew(-24deg, 0deg);
            -webkit-transform: skew(-24deg, 0deg);
            transform: skew(-24deg, 0deg);
            z-index: 8;
        }
    }
}

.partners_after {
    width: 55vw;
    height: var(--component-unhook-height);
    background: var(--utmb-color-white);
    position: absolute;
    right: 0;
    bottom: rem(-50px);
    z-index: 9;

    .partners_after_c {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            background: var(--utmb-color-white);
            transform-origin: top left;
            -ms-transform: skew(-24deg, 0deg);
            -webkit-transform: skew(-24deg, 0deg);
            transform: skew(-24deg, 0deg);
            z-index: 8;
        }
    }
}

// TO DO: Do we need this here?
.extended_logos {
    margin: rem(80px) 0;

    @include bp(md) {
        margin: 0;
    }
}

.item-extended {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: rem(125px);

    @media (orientation: portrait) {
        flex: 0 0 50%;
    }

    @media (orientation: landscape) {
        flex: 0 0 rem(125px);
        padding: rem(20px) rem(12px);
    }
}

.constrain {
    @media (orientation: landscape) {
        max-width: rem(700px);
        margin-left: auto;
        margin-right: auto;
    }
}
